<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>门店</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="门店名称">
                                <el-input v-model="search.store_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="负责人">
                                <el-input v-model="search.responsible"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="门店电话">
                                <el-input v-model="search.store_phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="城市">
                                <el-input v-model="search.city"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="company_name" label="所属企业" width="200">
                </el-table-column>
                <el-table-column prop="store_name" label="门店名称" width="200">
                </el-table-column>
                <el-table-column prop="state" label="门店状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state === 1">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state === 2" type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="publish_state" label="上架状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.publish_state === 0" type="warning">下架</el-tag>
                        <el-tag size="mini" v-if="scope.row.publish_state === 1" type="success">上架</el-tag>
                        <el-tag size="mini" v-if="scope.row.publish_state === 2" type="warning">审核中</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="publish_state" label="标签" min-width="200">
                    <template slot-scope="scope">
                        <el-tag :key="item.index" v-for="item in scope.row.store_tag" class="form-tag_colour-tag"
                            effect="dark" size="mini" :color="item.tag_colour">
                            {{ item.tag_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="responsible" label="负责人" width="150">
                </el-table-column>
                <el-table-column prop="store_phone" label="门店电话" width="180">
                </el-table-column>
                <el-table-column prop="city" label="城市" width="150">
                </el-table-column>
                <el-table-column prop="address" label="地址" min-width="200">
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="180">
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" width="180">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('store.store.getinfo')" @click="tosee(scope.row.store_uuid)" size="mini">查看
                        </el-button>
                        <el-button v-if="is_auth('store.store.examine')"
                            @click="operation_tip(scope.row.store_uuid, scope.row.store_name, 1)" type="success"
                            size="mini">通过
                        </el-button>
                        <el-button v-if="is_auth('store.store.examine')"
                            @click="operation_tip(scope.row.store_uuid, scope.row.store_name, 2)" type="danger"
                            size="mini">不通过
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '门店',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
            settagPage: false,
            // 标签数据
            tagdata: {
                store_name: '',
                store_tag: [],
            },
            tag_list: [],       // 标签列表
            select_tag: '',     // 选中的标签

        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                store_name: '',
                responsible: '',
                store_phone: '',
                city: '',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "store.store.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                examine_status: 0
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(store_uuid = '', store_name = '', state = '') {

            let tip = ''
            let options = {}
            // 通过
            if (state === 1) {
                tip = '审批通过【' + store_name + '】？';
                // 弹出二次确认
                this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(store_uuid, state)
                    })
                    .catch(() => {
                    });
            }
            // 不通过
            if (state === 2) {
                tip = '不通过【' + store_name + '】？';
                // 弹出二次确认
                this.$prompt("请输入不通过理由", tip)
                    .then(({ value }) => {
                        this.isoperation(store_uuid, state, value)
                    })
                    .catch(() => {
                    });
            }
        },
        // 操作
        isoperation(store_uuid = '', state, value) {
            let postdata = {
                api_name: "store.store.examine",
                token: this.Tool.get_l_cache('token'),
                store_uuid,
                examine_status: state,
            }
            if (state === 2) {
                postdata["examine_reason"] = value;
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = true
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(store_uuid = '') {
            this.$router.push({ path: '/store/store/details', query: { store_uuid: store_uuid } })
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}
</style>
